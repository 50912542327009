import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import Hero from '../components/hero';
import Hours from '../components/hours';
function About({ data: { henry, andrea, doreen } }) {
  return (
    <Layout>
      <Hero title="About Us" subtitle="Over 40 Years of Eye Care Excellence" color="light" />
      <section className="section content">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h2 className="title is-3">Address</h2>
              <p>
                73 Princeton St #201
                <br />
                Chelmsford, MA 01863
              </p>
              <p>
                <a className="button is-primary" href="https://goo.gl/maps/aeaK99gscqu">
                  Get Directions
                </a>
              </p>
            </div>
            <div className="column">
              <h2 className="title is-3">Contact Us</h2>
              <p>
                <strong>Phone:</strong> <br />
                <a href="tel:+19782568501">(978) 256-8501</a>
              </p>
              <p>
                <strong>Email:</strong> <br />
                <a href="mailto:info@boroyaneyeassociates.com">info@boroyaneyeassociates.com</a>
              </p>
            </div>

            <div className="column">
              <Hours />
            </div>
          </div>
          <hr />
          <p>
            At Boroyan Eye Associates, our team works closely together to provide you with the best
            visual care possible. Our primary focus is taking care of your vision over a lifetime.
            That close relationship with our patients is a cornerstone of our business.
          </p>
          <p>
            Doctor Henry Boroyan founded the practice in 1970 and has provided outstanding visual
            care to the local community ever since. He is joined by Doctor Andrea Vosbikian, optical
            technician Doreen Stanizzi, and an administrative staff that is dedicated to the
            lifetime care of your eyes.
          </p>

          <div className="columns">
            <div className="column">
              <figure className="image">
                <img
                  src={henry.childImageSharp.fluid.src}
                  srcSet={henry.childImageSharp.fluid.srcSet}
                  sizes={henry.childImageSharp.fluid.sizes}
                  alt="Dr. Henry Boroyan"
                />
              </figure>
              <h3 className="title heading is-5">Henry J. Boroyan</h3>
              <p className="subtitle heading is-6">Doctor of Optometry</p>
              <p>
                Doctor Henry J. Boroyan graduated from New England College of Optometry with honors
                and has continued to provide outstanding visual care in the local community,
                including pre-school screenings and serving on advisory boards. He has lectured both
                nationally and internationally on optometric care and has consulted for ophthalmic
                providers.
              </p>
            </div>

            <div className="column">
              <figure className="image">
                <img
                  src={andrea.childImageSharp.fluid.src}
                  srcSet={andrea.childImageSharp.fluid.srcSet}
                  sizes={andrea.childImageSharp.fluid.sizes}
                  alt="Dr. Andrea Vosbikian"
                />
              </figure>
              <h3 className="title heading is-5">Andrea Vosbikian</h3>
              <p className="subtitle heading is-6">Doctor of Optometry</p>
              <p>
                Doctor Andrea D. Vosbikian graduated from the New England College of Optometry with
                honors. She has received grants for pediatric research and has lectured regularly on
                optometric practice to her colleagues. Having worked in underserved populations as
                well as a local Veteran’s Hospital, she has extensive experience in medical eye
                conditions.
              </p>
            </div>

            <div className="column">
              <figure className="image">
                <img
                  src={doreen.childImageSharp.fluid.src}
                  srcSet={doreen.childImageSharp.fluid.srcSet}
                  sizes={doreen.childImageSharp.fluid.sizes}
                  alt="Doreen Stanizzi"
                />
              </figure>
              <h3 className="title heading is-5">Doreen Stanizzi</h3>
              <p className="subtitle heading is-6">Optical Technician</p>
              <p>
                Doreen excels in assisting patients find the perfect pair of glasses for their
                optical needs. She is highly knowledgable in speciality sports lenses, computer
                lenses, children’s eyewear, and general use glasses. Her passion for the profession
                is clearly evident when working with her.
              </p>
            </div>
          </div>
          <div className=" has-text-centered">
            <p className="heading title is-5">
              Plus: the best administrative staff in the business.
            </p>
            <p>
              Our administrative staff is fully trained to provide efficient, caring attention to
              all of our patients.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

About.propTypes = {};

export default About;

export const query = graphql`
  query {
    andrea: file(relativePath: { eq: "andrea-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    henry: file(relativePath: { eq: "henry-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    doreen: file(relativePath: { eq: "doreen-portrait.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
